export const navigation = [
  {
    heading: {
      uk: "Про школу",
      en: "About the school",
    },
    items: [
      {
        uk: "Про нас",
        en: "About us",
        link: "about",
      },
      {
        uk: "Вакансії",
        en: "Jobs",
        link: "jobs",
      },
    ],
  },
  {
    heading: {
      uk: "Англійська",
      en: "English",
    },
    items: [
      {
        uk: "Індивідуальні уроки",
        en: "Personal lessons",
        link: "personal-lessons",
      },
      {
        uk: "Дорослі групи",
        en: "Adult groups",
        link: "adults-group",
      },
      {
        uk: "Дитячі групи",
        en: "Kids groups",
        link: "kids-group",
      },
      {
        uk: "Курс вихідного дня Online",
        en: "Online weekend course",
        link: "online-group",
      },
    ],
  },
  {
    heading: {
      uk: "Інші мови",
      en: "Other Languages",
    },
    items: [
      {
        uk: "Індивідуальні уроки",
        en: "Personal lessons",
        link: "personal-lessons",
      },
      {
        uk: "Іспанська",
        en: "Spanish",
        link: "other-languages",
      },
      {
        uk: "Румунська",
        en: "Romanian",
        link: "other-languages",
      },
      {
        uk: "Польська",
        en: "Polish",
        link: "other-languages",
      },
      {
        uk: "Французька",
        en: "French",
        link: "other-languages",
      },
    ],
  },
  {
    heading: {
      uk: "Інше",
      en: "Other",
    },
    items: [
      {
        uk: "Програма лояльності",
        en: "Loyalty program",
        link: "loyalty-program",
      },
      {
        uk: "Тест онлайн",
        en: "Online test",
        link: "online-test",
      },
      {
        uk: "Контакти",
        en: "Contacts",
        link: "contacts",
      },
    ],
  },
];

export const memo = {
  uk: "створена у пам’ять про нашого друга і наставника Деніела Хейдена",
  en: "has been created in memory of our friend and mentor Daniel Hayden",
};
